import { IStoreState } from '../../reducers/types'
import { ISakState } from './types'
import { IStatus } from '../melding/types'

export const sakActionTypes = {
  INITIAL_STATE: <const>'sak/INITIAL_STATE',
  // GET_SAK: <const>'sak/GET_SAK',
  // GET_SAK_OK: <const>'sak/GET_SAK_OK',
  // GET_SAK_FAILED: <const>'sak/GET_SAK_FAILED',
  // GET_SAK_NOT_FOUND: <const>'sak/GET_SAK_NOT_FOUND',
  CLEAR_SAK: <const>'sak/CLEAR_SAK',
  CREATE_SAK: <const>'sak/CREATE_SAK',
  CREATE_SAK_OK: <const>'sak/CREATE_SAK_OK',
  CREATE_SAK_FAILED: <const>'sak/CREATE_SAK_FAILED',
}

export const sakActions = {
  // getSak: (sakNumber: string, shouldUpdateVurdering?: boolean) => ({
  //   type: sakActionTypes.GET_SAK,
  //   sakNumber,
  //   shouldUpdateVurdering,
  // }),
  //
  // getSakOk: (
  //   sakNumber: string,
  //   title: string,
  //   sakClosed: boolean,
  //   sakTilgang: string,
  //   sakHjemmel: string,
  //   sakAdded = false
  // ) => ({
  //   type: sakActionTypes.GET_SAK_OK,
  //   sakNumber,
  //   title,
  //   sakClosed,
  //   sakTilgang,
  //   sakHjemmel,
  //   sakAdded,
  // }),

  // getSakFailed: (sakNumber: string) => ({
  //   type: sakActionTypes.GET_SAK_FAILED,
  //   sakNumber,
  // }),
  //
  // getSakNotFound: (sakNumber: string) => ({
  //   type: sakActionTypes.GET_SAK_NOT_FOUND,
  //   sakNumber,
  // }),

  clearSak: () => ({
    type: sakActionTypes.CLEAR_SAK,
  }),

  createSak: (meldingId: string, status: IStatus) => ({
    type: sakActionTypes.CREATE_SAK,
    meldingId,
    status,
  }),

  createSakOk: (tittel, saksnummer) => ({
    type: sakActionTypes.CREATE_SAK_OK,
    tittel,
    saksnummer,
  }),

  createSakFailed: (error: string) => ({
    type: sakActionTypes.CREATE_SAK_FAILED,
    error,
  }),
}

export const sakSelectors = {
  getLoadingStatus: (
    state: IStoreState
  ): { loading: ISakState['loading']; error: ISakState['error'] } => ({
    loading: state.sak.loading,
    error: state.sak.error,
  }),

  getSakState: (state: IStoreState): ISakState => state.sak,

  getSakTitle: (state: IStoreState): ISakState['title'] => state.sak.title,
}
