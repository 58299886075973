import { ISakActions, ISakState } from './types'
import { commonRedux } from '@mattilsynet/mt-common'
import { sakActionTypes } from './index'

export const initialState: ISakState = {
  loading: false,
  sakNumber: '',
  title: '',
  sakFail: false,
  sakNotFound: false,
  sakClosed: false,
  sakHjemmel: '',
  sakTilgang: '',
  error: null,
  creating: false,
  created: false,
  adding: false,
  added: false,
}

export const sakReducer = commonRedux.createReducer<ISakState, ISakActions>(
  initialState,
  {
    // [sakActionTypes.GET_SAK]: (
    //   state: ISakState,
    //   { shouldUpdateVurdering }: { shouldUpdateVurdering?: boolean }
    // ) => ({
    //   ...state,
    //   loading: true,
    //   error: null,
    //   created: shouldUpdateVurdering ? false : state.created,
    //   adding: !!shouldUpdateVurdering,
    //   added: false,
    // }),
    //
    // [sakActionTypes.GET_SAK_OK]: (
    //   state: ISakState,
    //   {
    //     sakNumber,
    //     sakClosed,
    //     sakHjemmel,
    //     sakTilgang,
    //     title,
    //   }: {
    //     sakNumber: ISakState['sakNumber']
    //     sakClosed: ISakState['sakClosed']
    //     sakHjemmel: ISakState['sakHjemmel']
    //     sakTilgang: ISakState['sakTilgang']
    //     title: ISakState['title']
    //   }
    // ) => ({
    //   ...state,
    //   loading: false,
    //   sakNotFound: false,
    //   sakFail: false,
    //   error: null,
    //   sakNumber,
    //   sakClosed,
    //   sakHjemmel,
    //   sakTilgang,
    //   title,
    //   added: true,
    //   adding: false,
    // }),
    //
    // [sakActionTypes.GET_SAK_NOT_FOUND]: (
    //   state: ISakState,
    //   {
    //     sakNumber,
    //   }: {
    //     sakNumber: ISakState['sakNumber']
    //   }
    // ) => ({
    //   ...state,
    //   loading: false,
    //   sakNumber,
    //   sakNotFound: true,
    //   sakFail: false,
    //   sakClosed: false,
    //   sakHjemmel: '',
    //   sakTilgang: '',
    //   title: '',
    //   adding: false,
    // }),
    //
    // [sakActionTypes.GET_SAK_FAILED]: (
    //   state: ISakState,
    //   {
    //     sakNumber,
    //   }: {
    //     sakNumber: ISakState['sakNumber']
    //   }
    // ) => ({
    //   ...state,
    //   loading: false,
    //   sakNumber,
    //   sakNotFound: false,
    //   sakFail: true,
    //   sakClosed: false,
    //   sakHjemmel: '',
    //   sakTilgang: '',
    //   title: '',
    //   adding: false,
    // }),

    [sakActionTypes.CLEAR_SAK]: () => ({
      ...initialState,
    }),

    [sakActionTypes.CREATE_SAK]: () => ({
      ...initialState,
      loading: true,
      error: null,
      creating: true,
      created: false,
      added: false,
    }),

    [sakActionTypes.CREATE_SAK_OK]: (
      state: ISakState,
      {
        saksnummer,
        tittel,
      }: { saksnummer: ISakState['sakNumber']; tittel: ISakState['title'] }
    ) => ({
      ...state,
      loading: false,
      created: true,
      creating: false,
      sakNumber: saksnummer,
      title: tittel,
    }),

    [sakActionTypes.CREATE_SAK_FAILED]: (
      state: ISakState,
      { error }: { error: string }
    ) => ({
      ...state,
      loading: false,
      creating: false,
      error,
    }),
  }
)
