import React from 'react'
import {
  InfoBox,
  InfoText,
  LoadingSpinner,
  Row,
  Text,
} from '@mattilsynet/mt-ui'
import { kodeverk } from '@mattilsynet/mt-common'

export const parseSakTitleToHTML = (sakTitle) => {
  const regex = new RegExp('\\|(.*?)\\|', 'g')
  return sakTitle ? sakTitle.replace(regex, '<b>$1</b>') : ''
}

interface ISakCardProps {
  sakNumber: string
  title: string
  sakFail: boolean
  sakNotFound: boolean
  sakClosed: boolean
  sakTilgang: string
  sakHjemmel: string
  onCancelSak: () => void
  isLoading: boolean
  showSak: boolean
}

const SakCardInfo = ({
  sakNumber,
  title,
  onCancelSak,
  sakFail,
  sakNotFound,
  sakClosed,
  isLoading,
  sakTilgang,
  sakHjemmel,
  showSak,
}: ISakCardProps) => {
  const kodeverkResponse = kodeverk.hooks.useKodeverk('TILGANGSHJEMMEL')
  const hjemmelDesc = kodeverkResponse.data?.map((d) => {
    if (d.displayNames) {
      const no = d.displayNames.no
      if (no === sakHjemmel) {
        return d.displayNames ? d.displayNames.descr_ifelt : ''
      }
    }
  })
  const renderSak = () => {
    if (!sakNumber && !showSak) return

    if (isLoading) {
      return (
        <Row center>
          <LoadingSpinner title={'Laster sak...'} small />
        </Row>
      )
    }

    if (sakFail) {
      return (
        <InfoBox
          danger
          title="En feil oppstod under henting av sak"
          buttonText="Fjern"
          onClick={onCancelSak}
        >
          <InfoText title="Saksnummer" withBoldTitle>
            <p>{sakNumber}</p>
          </InfoText>
        </InfoBox>
      )
    }

    if (sakNotFound) {
      return (
        <InfoBox
          warning
          title="Sak finnes ikke i arkivet"
          buttonText="Fjern"
          onClick={onCancelSak}
        >
          <InfoText title="Saksnummer" withBoldTitle>
            <p>{sakNumber}</p>
          </InfoText>
        </InfoBox>
      )
    }

    if (sakClosed) {
      return (
        <InfoBox
          warning
          title="Saken er avsluttet i arkivet"
          buttonText="Fjern"
          onClick={onCancelSak}
        >
          <InfoText title="Saksnummer" withBoldTitle>
            <p>{sakNumber}</p>
          </InfoText>
          <InfoText title="Sakstittel" withBoldTitle>
            <div
              dangerouslySetInnerHTML={{
                __html: parseSakTitleToHTML(title),
              }}
            ></div>
          </InfoText>
        </InfoBox>
      )
    }

    if (sakTilgang === 'TILGANGSKODE$UO') {
      return (
        <InfoBox
          success
          title="Saken er lagt til"
          buttonText="Fjern"
          onClick={onCancelSak}
        >
          <InfoText title="Saksnummer" withBoldTitle>
            <p>{sakNumber}</p>
          </InfoText>
          <InfoText title="Sakstittel" withBoldTitle>
            <div
              dangerouslySetInnerHTML={{
                __html: parseSakTitleToHTML(title),
              }}
            ></div>
          </InfoText>
          <InfoText title="Saken er unntatt offentlighet" withBoldTitle>
            <p>{sakHjemmel}</p>
            <Text size="medium" weight="lighter">
              {hjemmelDesc}
            </Text>
          </InfoText>
        </InfoBox>
      )
    }

    return (
      <InfoBox
        success
        title="Saken er lagt til"
        buttonText="Fjern"
        onClick={onCancelSak}
      >
        {/*<InfoText title="Saksnummer" withBoldTitle>*/}
        {/*  <p>{sakNumber}</p>*/}
        {/*</InfoText>*/}
        {/*<InfoText title="Sakstittel" withBoldTitle>*/}
        {/*  <div*/}
        {/*    dangerouslySetInnerHTML={{*/}
        {/*      __html: parseSakTitleToHTML(title),*/}
        {/*    }}*/}
        {/*  ></div>*/}
        {/*</InfoText>*/}
      </InfoBox>
    )
  }
  return <div id="sak-card">{renderSak()}</div>
}

export default SakCardInfo
